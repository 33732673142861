var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2"
    },
    attrs: {
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "xml:space": "preserve",
      "xmlns:serif": "http://www.serif.com/",
      "width": "100%",
      "height": "100%",
      "viewBox": "-3 -3 56 56"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "none",
      "fill-rule": "nonzero",
      "stroke": "currentColor",
      "stroke-width": "2.5pt"
    },
    attrs: {
      "d": "M19.089,31.742C17.072,29.509 14.574,28.102 12.088,26.484C9.705,24.934 7.846,22.379 8.157,19.416C8.556,15.633 12.164,13.978 14.717,11.727C16.93,9.776 16.59,5.573 16.574,3.898C4.814,7.738 -1.563,20.654 2.459,32.213C6.463,43.718 19.37,49.862 30.902,45.724C30.998,45.689 30.97,45.545 30.869,45.544C29.584,45.532 28.209,45.764 26.955,45.393C25.643,45.006 24.487,44.111 23.788,42.935C22.574,40.887 22.376,38.357 21.604,36.135C21.046,34.523 20.235,33.013 19.089,31.742ZM35.489,2.984C33.708,4.289 31.927,5.593 30.146,6.899C29.961,8.542 29.779,10.769 29.774,13.413C29.77,16.253 29.971,18.431 30.117,19.984C30.469,23.692 30.714,23.613 31.089,27.528C31.27,29.414 31.329,30.647 31.66,33.013C32.076,35.98 32.414,37.126 33.29,38.127C33.793,38.705 34.278,39.007 36.004,39.899C37.391,40.616 39.306,41.572 41.661,42.642L39.432,36.242L46.461,36.413L42.289,30.871L48.747,29.007L43.204,25.156L48.747,21.27L42.118,19.384L46.175,13.898L39.289,14.012L41.661,7.612L35.26,9.727L35.489,2.984Z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }